import { lazy } from "react";

import { AuthLayout } from "components/Layout/AuthLayout";
import { Loadable } from "components/ui";
import { EXPIRED_SESSION_PATH } from "configs/app.config";
import Landing from "pages/Landing";

const Welcome = Loadable(lazy(() => import("pages/Welcome")));
const Login = Loadable(lazy(() => import("pages/Login")));
const SignUp = Loadable(lazy(() => import("pages/SignUp")));
const CheckEmail = Loadable(lazy(() => import("pages/CheckEmail")));
const ExpiredSession = Loadable(lazy(() => import("pages/ExpiredSession")));
const VerifyEmail = Loadable(lazy(() => import("pages/VerifyEmail")));
const ExpiredLink = Loadable(lazy(() => import("pages/ExpiredLink")));
const ForgotPassword = Loadable(lazy(() => import("pages/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("pages/ResetPassword")));
const HowItWorks = Loadable(lazy(() => import("pages/HowItWorks")));

const LoggedOutRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/welcome",
      element: <Welcome />,
    },
    {
      path: "/sign-in",
      element: <Login />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/check-email",
      element: <CheckEmail />,
    },
    {
      path: EXPIRED_SESSION_PATH,
      element: <ExpiredSession />,
    },
    {
      path: "/verify-email",
      element: <VerifyEmail />,
    },
    {
      path: "/expired-link",
      element: <ExpiredLink />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/how-it-works",
      element: <HowItWorks />,
    },
  ],
};

export default LoggedOutRoutes;
