"use client";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useLockedBody } from "usehooks-ts";

import { Button } from "components/ui";
import { LogoIcon } from "icons";

import BurgerButton from "./BurgerButton";

export default function MobileMenu({ className }: { className?: string }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLockedBody(isMenuOpen);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className={className}>
      <BurgerButton className="z-20" onClick={toggleMenu} isMenuOpen={isMenuOpen} />
      <CSSTransition
        classNames={{
          appearActive: "transition opacity-100",
          enter: "opacity-0",
          enterActive: "transition opacity-100",
          exitActive: "transition duration-100 opacity-0",
        }}
        in={isMenuOpen}
        timeout={300}
        unmountOnExit
      >
        <div className="absolute inset-0 z-10 mb-auto flex w-full flex-col bg-white">
          <div className="flex w-full shrink-0 flex-col self-start overflow-auto  px-5 pb-11">
            <div className="flex h-16 items-center">
              <LogoIcon className="w-28 text-blue-700" />
            </div>
            <nav className="mb-auto mt-6">
              <ul className="flex flex-col">
                {/* <li className="mb-8">
                  <Link className="text-blue-700" to="/">
                    <FormattedMessage id="header.offerings" />
                  </Link>
                </li> */}
                <li className="mb-8">
                  <Link className="text-grey-400" to="/how-it-works">
                    <FormattedMessage id="header.howItWorks" />
                  </Link>
                </li>
                <li className="mb-8">
                  <Link className="text-grey-400" to="/welcome">
                    <FormattedMessage id="header.joinEnviago" />
                  </Link>
                </li>
              </ul>
            </nav>
            <Button className="mb-2" component="Link" to="/sign-up">
              <FormattedMessage id="header.signUp" />
            </Button>
            <Button variant="outlined" component="Link" to="/sign-in">
              <FormattedMessage id="header.signIn" />
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
