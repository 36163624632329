import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";

import { Typography } from "components/Typography";
import useToggleClassName from "hooks/useToggleClassName";
import { CirclesIcon, LogoIcon } from "icons";

const outletPathnames = ["/", "/how-it-works"];

export const AuthLayout = () => {
  useToggleClassName("loggedOut");

  const location = useLocation();

  if (outletPathnames.includes(location.pathname)) {
    return <Outlet />;
  }

  return (
    <div className="h-full overflow-x-hidden lg:flex lg:bg-authLayout">
      <div className="relative hidden w-authLayout flex-col overflow-x-hidden bg-blue-700 pb-20 pr-15 pt-10 text-white lg:flex xl:pr-28">
        <div className="ml-auto flex h-full max-w-sm flex-col justify-between xl:max-w-lg">
          <div>
            <LogoIcon className="mb-24 w-52 flex-shrink-0" />
            <Typography className="mb-16" variant="h1-xl">
              <FormattedMessage id="authLayout.title" />
            </Typography>
            <Typography className="mb-6" variant="h5">
              <FormattedMessage id="authLayout.subtitle" />
            </Typography>
          </div>
          <div>
            <img className="ml-28 w-64" src="/images/auth-man.png" alt="man" />
            <img className="-ml-7 -mt-8 w-64" src="/images/auth-woman.png" alt="woman" />
          </div>
          <CirclesIcon className="absolute -right-8 bottom-0 w-56 lg:w-1/2" />
        </div>
      </div>
      <Outlet />
    </div>
  );
};
