import { lazy } from "react";

import { MainLayout } from "components/Layout/MainLayout";
import { Loadable } from "components/ui";

const Home = Loadable(lazy(() => import("pages/Home")));
const MyProfile = Loadable(lazy(() => import("pages/MyProfile")));
const Settings = Loadable(lazy(() => import("pages/Settings")));
const SettingsPaymentMethods = Loadable(lazy(() => import("pages/SettingsPaymentMethods")));
const SettingsWithdrawMethod = Loadable(lazy(() => import("pages/SettingsWithdrawMethod")));
const SettingsAddNewCard = Loadable(lazy(() => import("pages/SettingsAddNewCard")));
const SettingsUpdateWithdrawMethod = Loadable(
  lazy(() => import("pages/SettingsUpdateWithdrawMethod"))
);
const SettingsLegal = Loadable(lazy(() => import("pages/SettingsLegal")));
const SharedProfile = Loadable(lazy(() => import("pages/SharedProfile")));
const Payment = Loadable(lazy(() => import("pages/Payment")));
const PaymentSend = Loadable(lazy(() => import("pages/PaymentSend")));
const PaymentRequest = Loadable(lazy(() => import("pages/PaymentRequest")));
const Transactions = Loadable(lazy(() => import("pages/Transactions")));
const TransactionDetails = Loadable(lazy(() => import("pages/TransactionDetails")));
const PricePoints = Loadable(lazy(() => import("pages/PricePoints")));
const WithdrawFunds = Loadable(lazy(() => import("pages/WithdrawFunds")));
const WithdrawalDetails = Loadable(lazy(() => import("pages/WithdrawalDetails")));
const DepositDetails = Loadable(lazy(() => import("pages/DepositDetails")));
const TransactionDetailsUpload = Loadable(lazy(() => import("pages/TransactionDetailsUpload")));
const ChangePassword = Loadable(lazy(() => import("pages/ChangePassword")));
const Contacts = Loadable(lazy(() => import("pages/Contacts")));
const AccountInfo = Loadable(lazy(() => import("pages/AccountInfo")));

const LoggedInRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/profile",
      element: <MyProfile />,
    },
    {
      path: "/profile/price-point",
      element: <PricePoints />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/transactions",
      element: <Transactions />,
    },
    {
      path: "/transactions/transaction-details",
      element: <TransactionDetails />,
    },
    {
      path: "/transactions/transaction-details/upload-file",
      element: <TransactionDetailsUpload />,
    },
    {
      path: "/settings/payment-methods",
      element: <SettingsPaymentMethods />,
    },
    {
      path: "/settings/payment-methods/add-new-card",
      element: <SettingsAddNewCard />,
    },
    {
      path: "/settings/withdraw-method",
      element: <SettingsWithdrawMethod />,
    },
    {
      path: "/settings/withdraw-method/update-bank-account",
      element: <SettingsUpdateWithdrawMethod />,
    },
    {
      path: "/settings/legal",
      element: <SettingsLegal />,
    },
    {
      path: "/:username",
      element: <SharedProfile />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/payment/send",
      element: <PaymentSend />,
    },
    {
      path: "/payment/request",
      element: <PaymentRequest />,
    },
    {
      path: "/withdraw-funds",
      element: <WithdrawFunds />,
    },
    {
      path: "/withdraw-funds/withdrawal-details",
      element: <WithdrawalDetails />,
    },
    {
      path: "/withdraw-funds/deposit-details",
      element: <DepositDetails />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/contacts",
      element: <Contacts />,
    },
    {
      path: "/account-info",
      element: <AccountInfo />,
    },
  ],
};

export default LoggedInRoutes;
